<template>
  <div style="padding-bottom: 100px">
    <div class="top">
      <img
        class="homeBj"
        src="../assets/img/homeBj.png"
        loading="eager"
        alt=""
        @load="onHomeBjLoaded"
      />
      <div class="copywriting"></div>
    </div>

    <!-- 确保 homeBj 加载完成后再加载其他资源 -->
    <div v-if="homeBjLoaded">
      <!-- 视频播放 -->
      <!-- <div class="top" v-if="false">
        <video autoplay ref="vueMiniPlayer">
          <source
            src="../assets/1007520_drone_forest_forest_drone_landscape_22011401Drone4k0171080p12000br.mp4"
          />
        </video>
      </div>
      <div @click="togglePlay()" class="bnt" style="z-index: 999">
        暂停/播放
      </div> -->

      <div class="title">产品展示 <br /></div>
      <Product></Product>

      <!-- 智慧方案 -->
      <div class="SmartSolutionn">
        <div class="title" style="color: #fff">智慧方案 <br /></div>
        <div
          class="imgBox"
          :style="{
            paddingRight: `${paddingSize}px`,
            paddingLeft: `${paddingSize}px`,
          }"
        >
          <transition name="smart-solution-hover">
            <div class="solution-container">
              <div
                v-for="(item, index) in SmartSolutionnList"
                :key="index"
                class="SmartSolutionnList"
                :class="
                  SmartSolutionnIndex == index ? 'SmartSolutionnHover' : ''
                "
                @mouseenter="mouseenter(item, index)"
                @click="getSmartSolution(index)"
              >
                <img
                  :src="require(`@/assets/img/${item.imgUrl}`)"
                  loading="lazy"
                />
                <div class="name">
                  {{ item.name }}<br />
                  <span>{{ item.Description }}</span>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <div class="title">项目案例 <br /></div>
      <Vue3SeamlessScroll></Vue3SeamlessScroll>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, inject, defineAsyncComponent } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();
const windowWidth = inject("windowWidth");
const paddingSize = inject("paddingSize");
const vueMiniPlayer = ref(null);

// 控制是否加载其他资源的状态
const homeBjLoaded = ref(false);

function onHomeBjLoaded() {
  homeBjLoaded.value = true;
}

// 如果页面加载时 homeBj 已经加载完毕，则直接加载其他资源
onMounted(() => {
  const imgElement = document.querySelector(".homeBj");
  if (imgElement && imgElement.complete) {
    onHomeBjLoaded();
  }
});

// 监听 windowWidth 或 paddingSize 的变化
watch([windowWidth, paddingSize], ([newWindowWidth, newPaddingSize]) => {
  console.log("新的窗口宽度:", newWindowWidth);
  console.log("新的 padding 大小:", newPaddingSize);
});

// 使用 defineAsyncComponent 实现懒加载
const Product = defineAsyncComponent(() => import("../components/product.vue"));
const Vue3SeamlessScroll = defineAsyncComponent(() =>
  import("../components/Vue3SeamlessScroll.vue")
);

let SmartSolutionnIndex = ref(0);
const SmartSolutionnList = ref([
  {
    imgUrl: "SmartSolutionn.png",
    name: "智慧医院体系",
    Description: "简化复杂布局，提升管理效率。",
  },
  {
    imgUrl: "Smart_city.png",
    name: "智慧商区体系",
    Description: "契合区域主题，提升用户体验。",
  },
]);

function mouseenter(item, index) {
  console.log(item, index);
  SmartSolutionnIndex.value = index;
}

function getSmartSolution(index) {
  router.push({
    path: "/SmartSolution",
    query: { index: index },
  });
}

const togglePlay = () => {
  const player = vueMiniPlayer.value;
  if (player) {
    if (player.paused) {
      player.play();
    } else {
      player.pause();
    }
  }
};
</script>

<style scoped lang="scss">
.top {
  position: relative;
}

.bnt {
  position: absolute;
  top: 200px;
  right: 200px;
}

.homeBj {
  width: 100%;
  height: 1000px;
  border-radius: 0;
}

.copywriting {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 225px;
  color: #fff;
}

.SmartSolutionn {
  width: 100%;
  height: 820px;
  background-color: #060606;
  margin-top: 96px;
  padding: 1px 0;

  .imgBox {
    width: 100%;
    display: flex;
    position: relative;

    .name {
      position: absolute;
      left: 5%;
      bottom: 40px;
      font-size: 24px;
      color: #fff;

      span {
        font-size: 16px;
        color: #bababa;
      }
    }

    .solution-container {
      display: flex;
      width: 100%;

      .SmartSolutionnList {
        position: relative;
        width: 24%;
        height: 490px;
        margin: 0 10px;
        transition: width 0.5s ease-in-out; /* 添加过渡效果 */

        &.SmartSolutionnHover {
          width: 73%;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

/* 定义过渡动画 */
.smart-solution-hover-enter-active,
.smart-solution-hover-leave-active {
  transition: all 0.5s ease-in-out;
}

.smart-solution-hover-enter-from,
.smart-solution-hover-leave-to {
  opacity: 0;
  transform: scale(0.9);
}
</style>