import { createI18n } from 'vue-i18n';
import en from './components/language/en.js';
import zh from './components/language/zh.js';
let curLan = navigator.language.slice(0, 2)
 console.log(curLan,'curLan');
const i18n = createI18n({
  locale: navigator.language.slice(0, 2) || 'en', // 默认语言，可以根据浏览器语言自动设置
  fallbackLocale: 'en', // 如果当前选择的语言没有对应的翻译，则回退到这个语言
  messages: {
    en: en,
    zh: zh, 
  },
});
 
export default i18n;