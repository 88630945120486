<template>
  <div
    style="height: 100%; background-color: #f5f5f5; padding-bottom: 100px"
    :style="{
      paddingRight: `${paddingSize}px`,
      paddingLeft: `${paddingSize}px`,
    }"
  >
    <div class="title">
      联系我们 <br />
      <!-- <span>Our Products</span> -->
    </div>
    <div class="ContactUs">
      <div class="ContactUsBox">
        <div class="ul">
          <div class="ContactUsTile"><span>*</span> 电子邮箱</div>
          <input
            class="ContactUsInput"
            placeholder="请输入邮箱"
            v-model="dataObj.email"
          />
        </div>
        <div class="ul">
          <div class="ContactUsTile">电话</div>
          <input
            class="ContactUsInput"
            placeholder="请输入电话"
            v-model="dataObj.phone"
          />
        </div>
        <div class="ul">
          <div class="ContactUsTile"><span>*</span> 姓名</div>
          <input
            class="ContactUsInput"
            placeholder="请输入姓名"
            v-model="dataObj.name"
          />
        </div>
      </div>
      <div class="content">
        <div class="ContactUsTile"><span>*</span> 咨询内容</div>
        <textarea
          class="ContactUsTextarea"
          placeholder="请输入需要咨询的内容"
          v-model="dataObj.content"
        />
      </div>
      <!-- <div class="Language">
        <div class="ContactUsTile">语言偏好</div>
        <el-radio-group v-model="dataObj.language">
          <el-radio value="1" size="large">中文</el-radio>
          <el-radio value="2" size="large">English</el-radio>
        </el-radio-group>
      </div> -->
      <div class="bnt" @click="submit()">提交</div>
      <p style="color: #999999; font-size: 14px">
        *点击“提交”，即表示我授权杭州麦洛智能科技有限公司通过我提供的联系信息与我联系。
      </p>
    </div>
  </div>
</template>

<script setup >
import { ref, reactive, toRefs, onMounted, inject, watch } from "vue";
import { ElMessage } from "element-plus";
import { create } from "../request/api";
const windowWidth = inject("windowWidth");
const paddingSize = inject("paddingSize");
// 可选：监听 windowWidth 或 paddingSize 的变化
watch([windowWidth, paddingSize], ([newWindowWidth, newPaddingSize]) => {
  console.log("新的窗口宽度:", newWindowWidth);
  console.log("新的 padding 大小:", newPaddingSize);
});
let dataObj = ref({
  name: "",
  phone: "",
  email: "",
  content: "",
  language: "1",
});
// 电子邮箱验证
function validateEmail(email) {
  const re = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/;
  return re.test(String(email).toLowerCase());
}
// 手机号验证
function validatePhone(phone) {
  const re = /^1[3-9]\d{9}$/;
  return re.test(phone);
}
function submit() {
  const { name, phone, email, content, language } = dataObj.value;

  if (!name) {
    ElMessage.error("姓名不能为空");
    return;
  }

  // if (!phone) {
  //   ElMessage.error("电话不能为空");
  //   return;
  // }
  if (phone && !validatePhone(phone)) {
    ElMessage.error("电话号码格式不正确");
    return;
  }
  if (!email) {
    ElMessage.error("电子邮件不能为空");
    return;
  }

  if (!validateEmail(email)) {
    ElMessage.error("电子邮件格式不正确");
    return;
  }

  if (!content) {
    ElMessage.error("咨询内容不能为空");
    return;
  }

  // 提交表单数据
  create(dataObj.value)
    .then((res) => {
      console.log(res.data);
      if (res.data.code == 200 || res.data.code == 0) {
        ElMessage.success("提交成功");
        // 清空表单
        dataObj.value = {
          name: "",
          phone: "",
          email: "",
          content: "",
          language: "1",
        };
      } else {
        ElMessage.error("提交失败，请稍后再试");
      }
    })
    .catch((error) => {
      ElMessage.error("提交失败，请稍后再试");
      console.error(error);
    });
}
</script>
<style scoped lang="scss">
.title {
  padding-top: 60px;
}
.ContactUs {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 60px 100px;
  font-size: 16px;
  .ContactUsBox {
    width: 100%;
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: space-between;
    .ul {
      width: 440px;
      height: 72px;
      margin-bottom: 40px;

      font-weight: 400;
      .ContactUsTile {
        margin-bottom: 10px;
        span {
          color: #c33730;
        }
      }
      .ContactUsInput {
        border: none;
        width: 100%;
        height: 40px;
        background-color: #f5f5f5;
        font-size: 14px;
      }
    }
  }
  .ContactUsTextarea {
    margin-top: 10px;
    border: none;
    background-color: #f5f5f5;
    width: 100%;
    height: 300px;
  }
  .ContactUsTile {
    margin-top: 40px;
    span {
      color: #c33730;
    }
  }
  .bnt {
    width: 200px;
    height: 40px;
    background-color: #c33730;
    text-align: center;
    line-height: 40px;
    margin-top: 60px;
    color: #fff;
    border-radius: 2px;
  }
  .bnt:hover {
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
  }
}
</style>