<template>
  <div class="container">
    <div class="title">
      <!-- {{ data.name }} <br /> -->
      {{ data.name }}<br />
      <!-- <span>Our Products</span> -->
    </div>
    <!-- <div class="content">
      {{ data.content }}
    </div> -->
    <div
      class="imgBox"
      :style="{
        paddingRight: `${paddingSize - 30}px`,
        paddingLeft: `${paddingSize}px`,
      }"
    >
      <div
        v-for="(item, index) in data.imgList"
        :key="index"
        class="img"
        :style="{
          marginRight: `${paddingSize / 12}px`,
        }"
      >
        <img :src="require(`@/assets/img/${item}`)" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, toRefs, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
const route = useRoute();
const data = route.query;
console.log(data);
</script>

<style scoped lang="scss">
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 100px;
}

.title {
  text-align: center;
  font-size: 2em;
  margin-bottom: 60px;
  span {
    font-size: 1.2em;
    color: #666;
  }
}

.content {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 40px;
  margin-top: 60px;
}

.imgBox {
  width: 100%;
  .img {
    width: 100%;
    margin: 0;
    font-size: 0;
    img {
      width: 100%;
      // height: 280px;
      border-radius: 0;
      object-fit: cover;
    }
  }
}
</style>