<template>
  <div>
    <div class="top">
      <img class="homeBj" src="../assets/img/advantageBj.png" alt="" />
      <div class="copywriting">
        <!-- <p style="font-size: 24px">
          Explore the threads of the universe,and become a beacon for the world.
        </p> -->
        <p style="font-size: 40px; margin-top: 32px">
          探索宇宙的脉络，做世界的引路人
        </p>
      </div>
    </div>
    <div class="title">
      企业优势 <br />
      <!-- <span>Company Advantages</span> -->
    </div>
    <div
      class="body"
      :style="{
        paddingRight: `${paddingSize}px`,
        paddingLeft: `${paddingSize}px`,
      }"
    >
      <div v-for="(item, index) in dataObj" :key="index" class="content">
        <div class="contentTitle">{{ item.title }}</div>
        <div style="text-indent: 2rem">
          {{ item.content }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup >
import { ref, reactive, toRefs, onMounted, inject, watch } from "vue";
const windowWidth = inject("windowWidth");
const paddingSize = inject("paddingSize");
// 可选：监听 windowWidth 或 paddingSize 的变化
watch([windowWidth, paddingSize], ([newWindowWidth, newPaddingSize]) => {
  console.log("新的窗口宽度:", newWindowWidth);
  console.log("新的 padding 大小:", newPaddingSize);
});
const dataObj = ref([
  {
    content:
      "杭州麦洛智能科技有限公司提供从智慧标识规划、设计、研发到制造的一体化综合服务，具备完善的服务链条，能够为客户提供全方位的解决方案。",
    title: "一体化服务",
  },
  {
    content:
      "麦洛专注于智慧导视大屏、索引屏、科室小屏等医院导视系统产品，能够解决医院复杂布局问题，提升患者和医护人员的时间效率，确保信息准确、实时推送，改善患者体验。",
    title: "精准导视",
  },
  {
    content:
      "拥有多套成熟的标准化产品，并能够根据客户需求进行灵活调整和优化，提供个性化的解决方案，确保产品能完全契合客户场景。",
    title: "个性定制",
  },
  {
    content:
      "公司拥有专业且经验丰富的研发团队，能够快速应对市场变化并提供定制化服务。售后团队成员工作高效、响应迅速，为客户提供全方位的技术支持和优质的服务保障。",
    title: "专业团队",
  },
]);
</script>
<style scoped lang="scss">
.homeBj {
  width: 100%;
  height: 1000px;
}
.copywriting {
  text-align: center;
  position: absolute;
  bottom: 170px;
  left: 53%;
  color: #fff;
}
.body {
  width: 100%;
  padding-bottom: 60px;
  font-size: 20px;
  .content {
    .contentTitle {
      margin: 20px 0;
      font-weight: 800;
    }
  }
}
</style>