<template>
  <div style="background-color: #f5f5f5">
    <div
      class="ul"
      :style="{
        paddingRight: `${paddingSize}px`,
        paddingLeft: `${paddingSize}px`,
      }"
    >
      <div class="title">
        麦洛动态 <br />
        <!-- <span>Project Case Studies</span> -->
      </div>
      <div
        class="li"
        v-for="(item, index) in dataObj"
        :key="index"
        @click="getDetails(item)"
      >
        <img :src="require(`@/assets/img/${item.imgUrl}`)" alt="" />
        <div class="liRight">
          <div class="liTitle">{{ item.title }}</div>
          <div class="content">{{ item.content }}</div>
          <div class="tag">{{ item.tag }}</div>
          <div class="time">{{ item.time }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup >
import { ref, reactive, toRefs, onMounted, inject, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const windowWidth = inject("windowWidth");
const paddingSize = inject("paddingSize");
// 可选：监听 windowWidth 或 paddingSize 的变化
watch([windowWidth, paddingSize], ([newWindowWidth, newPaddingSize]) => {
  console.log("新的窗口宽度:", newWindowWidth);
  console.log("新的 padding 大小:", newPaddingSize);
});
const dataObj = ref([
  {
    title: "我们是麦洛智能",
    content: "欢迎您关注杭州麦洛智能科技有限公司。",
    Description: [
      {
        content: "欢迎您关注杭州麦洛智能科技有限公司。",
      },
      {
        content:
          "我们相信智慧标识不仅仅是传统标牌的智能化升级，它更是智慧城市建设中信息流动的“桥梁”和“纽带”。智慧标识以其先进的显示技术和智能化功能，将动态、精准的信息即时传递给市民与访客，解决了许多信息传递滞后、误差和不准确的问题。这些标识设备不仅能提供定位导航、实时信息更新，还能根据不同的需求进行互动与服务，极大提升了城市的管理效率和市民的生活体验。",
      },
      {
        content:
          "公司自成立以来，一直致力于智慧标识技术的研发与创新，秉承着“让信息更高效流通，让服务更便捷”的企业使命，积极推动智慧标识产品的普及与应用。我们的智慧导览大屏、索引屏、科室小屏等产品，已经在多个医院投入使用，解决了大量信息不畅通、路径不明确等问题，获得了用户的一致好评。在技术研发方面，我们不断优化产品性能，利用AI运算、图算法等先进技术，提升信息推送的准确性与实时性，为智慧城市的建设贡献着我们的力量。",
      },
      {
        content:
          "此外，我们还注重与各大行业合作，推动智慧标识在更广泛的领域应用。我们相信，随着技术的不断进步，智慧标识将不止于单一功能的展示，它将成为连接城市与市民之间的信息流通平台，实现更加智能化、数字化、便捷化的城市管理与服务。",
      },
      {
        content:
          "杭州麦洛智能科技有限公司始终秉持创新与服务并重的理念，不断推动智慧标识产业的发展，致力于为建设更加智能、高效、便捷的城市环境而努力。未来，我们将继续与合作伙伴携手并肩，共同迈向智慧城市的美好未来。",
      },
    ],
    imgUrl: "Dynamic_stateLogo.png",
    tag: "行内资讯",
    time: "2024-11-25",
    author: "麦洛市场部",
  },
]);
function getDetails(item) {
  router.push({
    path: "/DynamicDetails",
    query: { item: JSON.stringify(item) },
  });
}
</script>
<style scoped lang="scss">
.ul {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 100px;
  .li {
    width: 100%;
    height: 240px;
    padding: 30px;
    display: flex;
    background-color: #fff;
    margin-bottom: 30px;
    font-size: 14px;
    color: #060606;
    cursor: pointer;
    .liRight {
      position: relative;
      flex: 1;
      margin-left: 50px;
      .liTitle {
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 16px;
      }
      .content {
        color: #666666;
        overflow: hidden; //多出的隐藏
        text-overflow: ellipsis; //多出部分用...代替
        display: -webkit-box; //定义为盒子模型显示
        -webkit-line-clamp: 2; //用来限制在一个块元素显示的文本的行数
        -webkit-box-orient: vertical; //从上到下垂直排列子元素（设置伸缩盒子的子元素排列方式）
      }
      .tag {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 28px;
        padding: 4px 10px;
        color: #bababa;
        border: #bababa 1px solid;
        border-radius: 4px;
      }
      .time {
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
    img {
      width: 320px;
      height: 180px;
      border-radius: 8px;
    }
  }
}
</style>