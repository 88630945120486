<template>
  <div class="container">
    <div class="title">
      {{ data.title }} <br />
      <span>{{ data.time }}</span>
      <span style="margin-left: 80px">编者：{{ data.author }}</span>
    </div>
    <div class="content" v-for="(item, index) in data.Description" :key="index">
      {{ item.content }}
    </div>
  </div>
</template>

<script setup >
import { ref, reactive, toRefs, onMounted, defineProps } from "vue";
import { useRouter, useRoute } from "vue-router";
const route = useRoute();
const data = JSON.parse(route.query.item);
console.log(data);
onMounted(() => {});
</script>
<style scoped lang="scss">
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  padding-top: 100px;
}

.title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 60px;
  span {
    font-size: 20px;
    color: #bababa;
  }
}

.content {
  font-size: 20px;
  margin-bottom: 20px;
  text-indent: 2em;
  color: #666666;
}
</style>