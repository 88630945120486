import { createRouter, createWebHashHistory } from "vue-router";
import home from "../pages/index.vue";
import detailsContent from "../components/detailsContent.vue";
import DynamicDetails from "../components/DynamicDetails.vue";
import SmartSolution from "../pages/SmartSolution.vue";
import CompanyAdvantages from "../pages/CompanyAdvantages.vue";
import CompanyNews from "../pages/CompanyNews.vue";
import ContactUs from "../pages/ContactUs.vue";
import ProductIntroduction from "../pages/ProductIntroduction.vue";
import ProjectCaseStudies from "../pages/ProjectCaseStudies.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: home,
  },
  {
    path: "/SmartSolution",
    name: "SmartSolution",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/SmartSolution.vue"),
  },
  {
    path: "/CompanyAdvantages",
    name: "CompanyAdvantages",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/CompanyAdvantages.vue"),
  },
  {
    path: "/CompanyNews",
    name: "CompanyNews",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/CompanyNews.vue"),
  },
  {
    path: "/ContactUs",
    name: "ContactUs",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/ContactUs.vue"),
  },
  {
    path: "/ProductIntroduction",
    name: "ProductIntroduction",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../pages/ProductIntroduction.vue"
      ),
  },
  {
    path: "/ProjectCaseStudies",
    name: "ProjectCaseStudies",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../pages/ProjectCaseStudies.vue"),
  },
  {
    path: "/detailsContent",
    name: "detailsContent",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/detailsContent.vue"
      ),
  },
  {
    path: "/DynamicDetails",
    name: "DynamicDetails",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/DynamicDetails.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
