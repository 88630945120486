<template>
  <topBox class="topBox"></topBox>
  <consult class="consult"></consult>
  <div ref="routerViewContainer">
    <router-view />
  </div>
  <bottomNav></bottomNav>
</template>

<script setup>
import {
  ref,
  provide,
  onMounted,
  onBeforeUnmount,
  watch,
  defineAsyncComponent,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import topBox from "./components/topNav.vue";
const bottomNav = defineAsyncComponent(() =>
  import("./components/bottomNav.vue")
);
const consult = defineAsyncComponent(() => import("./components/consult.vue"));
const router = useRouter();
const route = useRoute();
const routerViewContainer = ref(null);

// 获取浏览器宽度
const rawWindowWidth = ref(
  window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
);

// 计算后的窗口宽度
const windowWidth = ref(rawWindowWidth.value);

// 计算 padding 大小
const paddingSize = ref(0);

// 监听窗口大小变化
const handleResize = () => {
  const newWidth = window.innerWidth;
  rawWindowWidth.value = newWidth;
  updatePaddingSize(newWidth);
};

// 更新 paddingSize 的函数
const updatePaddingSize = (width) => {
  if (width > 1200) {
    paddingSize.value = (width - 1200) / 2;
  } else if (width > 900) {
    paddingSize.value = (width - 900) / 2;
  } else {
    paddingSize.value = 20;
  }
};

// 初始化时更新 paddingSize
onMounted(() => {
  updatePaddingSize(rawWindowWidth.value);
  window.addEventListener("resize", handleResize);

  // 监听路由变化并滚动到顶部
  router.afterEach((to, from) => {
    window.scrollTo(0, 0);
    if (routerViewContainer.value) {
      routerViewContainer.value.scrollTop = 0;
    }
  });
});

// 清理事件监听器
onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});

// 提供计算后的窗口宽度和 paddingSize
provide("windowWidth", windowWidth);
provide("paddingSize", paddingSize);

// 监听 rawWindowWidth 的变化并更新 windowWidth 和 paddingSize
watch(rawWindowWidth, (newWidth) => {
  windowWidth.value = newWidth;
  updatePaddingSize(newWidth);
});
</script>

<style lang="scss">
body,
html {
  margin: 0;
  padding: 0;
  color: #060606;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-family: "PingFang SC", "Microsoft YaHei", sans-serif;
  font-weight: 500; /* 中黑体对应的权重 */
}
.topBox {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #fff;
}
.consult {
  position: fixed;
  right: 138px;
  bottom: 100px;
  z-index: 99 !important;
}

::-webkit-scrollbar {
  display: none;
}
.title {
  width: 100%;
  text-align: center;
  margin-top: 100px;
  margin-bottom: 60px;
  font-size: 32px;
  color: #060606;
  span {
    font-size: 24px;
    color: #bababa;
  }
}
div {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
img {
  object-fit: cover;
  border-radius: 8px;
}
input:focus,
textarea:focus {
  outline: none;
}
input,
textarea {
  padding: 10px 20px;
  box-sizing: border-box;
}
</style>