import axios from "axios";
// let token = localStorage.getItem('token')
console.log(process.env.VUE_APP_API_URL, "process.env.VUE_APP_API_URL");
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  // baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000, // request timeout
  // headers: {
  //     token
  // }
});
service.interceptors.response.use((res) => {
  console.log(res.data, "响应拦截器");
  // if (res.data.code == 415) {
  //     localStorage.removeItem("token")
  //     window.location.href = "/"
  // } else {
  //     return res
  // }
  return res;
});
export default service;
