<template>
  <div
    v-if="windowWidth >= 1020"
    class="topBox"
    :style="{
      backgroundColor:
        navIndex == 0 || navIndex == 3 || navIndex == 4
          ? 'rgba(6, 6, 6, 0.4)'
          : '#fff',
    }"
  >
    <div class="logo" @click="navChange()">
      <img src="../assets/img/logo.png" alt="" />
    </div>
    <div class="body">
      <div
        class="navList"
        v-for="(item, index) in navList"
        :key="index"
        @click="navChange(item, index)"
        :style="{
          color:
            index == navIndex
              ? '#c33730'
              : navIndex == 0 || navIndex == 3 || navIndex == 4
              ? '#fff'
              : '#060606',
          marginLeft: windowWidth < 1100 ? '10px' : '20px',
          marginRight: windowWidth < 1100 ? '10px' : '20px',
        }"
      >
        <div class="en">
          {{ item.en }}
        </div>
        <!-- <div class="zh">{{ item.zh }}</div> -->
      </div>
    </div>
  </div>

  <!-- tap长度不够 -->
  <div v-else-if="!menuTrue" class="topBox">
    <div class="logo">
      <img src="../assets/img/logo.png" alt="" />
    </div>
    <div class="tap" @click="menuChange()">
      <img src="../assets/img/tapList.png" alt="" />
    </div>
  </div>
  <!-- 菜单栏 -->
  <div class="menu" v-if="menuTrue && windowWidth < 1020">
    <div class="menu_top">
      <img @click="menuChange()" src="../assets/icon/Off.png" alt="" />
    </div>
    <div class="ul">
      <div
        class="li"
        v-for="(item, index) in navList"
        :key="index"
        @click="navChange(item, index)"
      >
        <p>{{ item.en }}</p>
        <img src="../assets/icon/right_arrow.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup >
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
let menuTrue = ref(false);
import {
  ref,
  reactive,
  toRefs,
  onMounted,
  onBeforeRouteUpdate,
  inject,
  watch,
  effect,
} from "vue";
const windowWidth = inject("windowWidth");
const paddingSize = inject("paddingSize");
// 可选：监听 windowWidth 或 paddingSize 的变化
watch([windowWidth, paddingSize], ([newWindowWidth, newPaddingSize]) => {
  console.log("新的窗口宽度:", newWindowWidth);
  if (newWindowWidth > 1000) {
    menuTrue.value = false;
  }
  console.log("新的 padding 大小:", newPaddingSize);
});
let pathName = ref("");
const navList = ref([]);
const navIndex = ref(0);
navList.value = [
  {
    zh: "HOME",
    en: "首页",
    path: "/",
  },
  {
    zh: "SMART SOLUTION",
    en: "智慧方案",
    path: "/SmartSolution",
  },
  {
    zh: "PRODUCT TNTRODUCTION",
    en: "产品介绍",
    path: "/ProductIntroduction",
  },
  {
    zh: "PROJECT CASE STUDIES",
    en: "项目案例",
    path: "/ProjectCaseStudies",
  },
  {
    zh: "COMPANY ADVANTAGES",
    en: "企业优势",
    path: "/CompanyAdvantages",
  },
  {
    zh: "COMPANY NEWS",
    en: "企业动态",
    path: "/CompanyNews",
  },
  {
    zh: "CONTACT US",
    en: "联系我们",
    path: "/ContactUs",
  },
];
function navChange(item, index) {
  navIndex.value = index;
  menuTrue.value = false;
  if (index == 0) {
    router.push({
      path: "/",
    });
  } else if (index == 1) {
    router.push({
      path: "/SmartSolution",
    });
  } else if (index == 2) {
    router.push({
      path: "/ProductIntroduction",
    });
  } else if (index == 3) {
    router.push({
      path: "/ProjectCaseStudies",
    });
  } else if (index == 4) {
    router.push({
      path: "/CompanyAdvantages",
    });
  } else if (index == 5) {
    router.push({
      path: "/CompanyNews",
    });
  } else if (index == 6) {
    router.push({
      path: "/ContactUs",
    });
  } else {
    navIndex.value = 0;
    router.push({
      path: "/",
    });
  }
  console.log(item, index);
}
function menuChange() {
  menuTrue.value = !menuTrue.value;
}
onMounted(() => {
  navChange();
});

watch(
  () => router.currentRoute.value,
  (newValue) => {
    console.log("新路由", newValue.path);
    navList.value.forEach((item, index) => {
      if (newValue.path == item.path) {
        navIndex.value = index;
      }
    });
  },
  { immediate: true }
);
</script>
<style scoped lang="scss">
.topBox {
  width: 100%;
  height: 100px;
  background-color: rgba(6, 6, 6, 0.4);
  padding: 35px 50px;
  border-bottom: #c33730 0.2px solid;
  .tap {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 35px;
    right: 50px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 0;
    }
  }
  .logo {
    position: absolute;
    top: 35px;
    left: 50px;
    img {
      border-radius: 0;
    }
  }
}
.menu {
  width: 100%;
  height: 1000px;
  background-color: #ffffff;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  font-size: 22px;
  color: #060606;
  .ul {
    width: 100%;
    .li {
      height: 100%;
      height: 70px;
      display: flex;
      padding: 0 40px;
      padding-right: 24px;
      justify-content: space-between;
      img {
        display: none;
        margin-top: 27px;
        width: 16px;
        height: 16px;
      }
    }
    .li:hover {
      background-color: #f8f5f5;
      img {
        display: block;
      }
    }
  }
  .menu_top {
    width: 100%;
    height: 70px;
    img {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 27px;
      right: 20px;
    }
  }
}
.body {
  display: flex;
  justify-content: center;
  .navList {
    text-align: center;
    margin: 0 20px;
    .en {
      font-size: 18px;
      margin-bottom: 4px;
    }
    .en:hover {
      cursor: pointer;
      color: #c33730 !important;
    }
    .zh {
      font-size: 10px;
    }
  }
}
</style>