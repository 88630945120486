<template>
  <div>
    <div class="top">
      <img
        class="homeBj"
        src="../assets/img/caseBj.png"
        loading="eager"
        alt=""
      />
      <div class="copywriting">
        <!-- <p
          style="
            font-family: Alegreya Sans SC ExtraBold Italic;
            font-size: 24px;
          "
        >
          Explore the threads of the universe,and become a beacon for the world.
        </p> -->
        <p style="font-size: 40px; margin-top: 32px">
          探索宇宙的脉络，做世界的引路人
        </p>
      </div>
    </div>
    <div class="title">
      项目案例 <br />
      <!-- <span>Our Products</span> -->
    </div>
    <div
      class="imgBox"
      :style="{
        paddingRight: `${paddingSize}px`,
        paddingLeft: `${paddingSize}px`,
      }"
    >
      <div
        class="case"
        v-for="(item, index) in casetList"
        :key="index"
        @click="detailsChange(item)"
        @mouseover="item.showMask = true"
        @mouseleave="item.showMask = false"
      >
        <img :src="require(`@/assets/img/${item.imgUrl}`)" loading="lazy" />
        <div class="name">
          {{ item.name }}
        </div>
        <transition name="fade">
          <div v-if="item.showMask" class="mask">
            <div class="about-button">ABOUT THIS</div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script setup >
import { ref, reactive, toRefs, onMounted, inject, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const windowWidth = inject("windowWidth");
const paddingSize = inject("paddingSize");
// 可选：监听 windowWidth 或 paddingSize 的变化
watch([windowWidth, paddingSize], ([newWindowWidth, newPaddingSize]) => {
  console.log("新的窗口宽度:", newWindowWidth);
  console.log("新的 padding 大小:", newPaddingSize);
});
import detailsContent from "../components/detailsContent.vue";
const detailsData = ref({});
let detailsTrue = ref(false);
//项目案例数据
let casetList = reactive([
  {
    imgUrl: "item1.png",
    name: "杭州市一医院（城北院区）",
    imgList: [
      "lnb_item1_1.png",
      "lnb_item1_2.png",
      "lnb_item1_3.png",
      "lnb_item1_4.png",
      "lnb_item1_5.png",
      "lnb_item1_6.png",
    ],
    content: "这是介绍：有可能是HTML",
    showMask: false,
  },
  {
    imgUrl: "item2.png",
    name: "宁波大学附属医院（方桥院区）",
    imgList: [
      "nb_item2_1.png",
      "nb_item2_2.png",
      "nb_item2_3.png",
      "nb_item2_4.png",
      "nb_item2_5.png",
    ],
    content: "这是介绍：有可能是HTML",
    showMask: false,
  },
  {
    imgUrl: "item3.png",
    name: "杭州市中医院（丁桥园区）",
    imgList: [
      "zy_item3_1.png",
      "zy_item3_2.png",
      "zy_item3_3.png",
      "item3.png",
    ],
    content: "这是介绍：有可能是HTML",
    showMask: false,
  },
]);
function detailsChange(item) {
  // detailsData.value = item;
  // detailsTrue.value = true;
  router.push({
    path: "/detailsContent",
    query: item,
  });
}
</script>
<style scoped lang="scss">
.homeBj {
  width: 100%;
  height: 1000px;
}
.copywriting {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 225px;
  color: #fff;
}

.imgBox {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
  .case {
    width: 32%;
    height: auto;
    position: relative;
    margin-bottom: 30px;
    img {
      width: 100%;
      height: 280px;
      border-radius: 10px;
      object-fit: cover;
    }
    .name {
      position: absolute;
      left: 16px;
      bottom: 16px;
      color: #fff;
      font-size: 16px;
    }
    //  蒙版动画
    .fade-enter-active,
    .fade-leave-active {
      transition: opacity 0.3s ease;
    }
    // 蒙版统一样式
    .mask {
      width: 100%;
      height: 280px;
      background-color: rgba(255, 182, 193, 0.4); /* 红色蒙版 */
      position: absolute;
      top: 0;
      left: 0;
      transition: opacity 0.3s ease;
      border-radius: 10px;
    }
    // 蒙版按钮统一样式
    .about-button {
      font-size: 12px;
      border-radius: 17px;
      color: #c33730;
      background-color: #fff;
      padding: 8px 10px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%);
      transition: opacity 0.3s ease;
    }
    .about-button:hover {
      background-color: #c33730;
      color: #fff;
    }
  }
  .case:hover {
    cursor: pointer;
  }
}
</style>