import request from "./http";
// 用户登录
// export function login(data) {
//     return request({
//         url: `/user/login`,
//         method: 'post',
//         data
//     })
// }
// 用户登录
export function create(data) {
  return request({
    url: `/admin-api/website/content/create`,
    method: "post",
    data,
  });
}
