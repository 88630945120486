<template>
  <div class="top">
    <div class="imgBox">
      <div
        class="tap"
        v-for="(item, index) in tapList"
        :key="index"
        @click="tapChange(index)"
      >
        <img :src="require(`@/assets/img/${item.imgUrl}`)" alt="" />
        <div :style="{ color: index == tapIndex ? '#c33730' : '#060606' }">
          {{ item.title }}
        </div>
      </div>
    </div>
  </div>
  <div v-if="tapIndex == 1">
    <div class="list" v-for="(item, index) in witMallList" :key="index">
      <img :src="require(`@/assets/img/${item.imgUrl}`)" alt="" />
      <!-- <div class="advantageBox">
        <p v-for="(items, indexs) in item.advantage" :key="indexs">
          {{ items }}
        </p>
      </div> -->
    </div>
  </div>
  <div v-else>
    <div class="list" v-for="(item, index) in witNosocomiumList" :key="index">
      <img :src="require(`@/assets/img/${item.imgUrl}`)" alt="" />
      <!-- :style="{ height: windowWidth > 1920 ? '(1000*windowWidth/1920)' : '' }" -->

      <!-- <div class="advantageBox">
        <p v-for="(items, indexs) in item.advantage" :key="indexs">
          {{ items }}
        </p>
      </div> -->
    </div>
  </div>
</template>

<script setup >
import { ref, reactive, toRefs, onMounted, inject, watch } from "vue";
const windowWidth = inject("windowWidth");
const paddingSize = inject("paddingSize");
import { useRouter, useRoute } from "vue-router";
const route = useRoute();
const data = route.query.index;
// 可选：监听 windowWidth 或 paddingSize 的变化
watch(
  [windowWidth, paddingSize, route],
  ([newWindowWidth, newPaddingSize, newRoute]) => {
    console.log("新的窗口宽度:", newWindowWidth);
    console.log("新的 padding 大小:", newPaddingSize);
    tapIndex.value = newRoute.query.index;
  }
);
const tapIndex = ref(0);
if (data) {
  tapIndex.value = data;
}
const tapList = ref([
  {
    imgUrl: "nosocomium.png",
    title: "智慧医院",
  },
  {
    imgUrl: "mall.png",
    title: "智慧商区",
  },
]);
const witMallList = ref([
  {
    imgUrl: "option_one.png",
    advantage: [
      "闪光点闪光点闪光点闪光点闪光点闪光点闪",
      "闪光点闪光点闪光点闪光点闪光点闪光点闪",
    ],
  },
  {
    imgUrl: "option_two.png",
    advantage: [
      "闪光点闪光点闪光点闪光点闪光点闪光点闪",
      "闪光点闪光点闪光点闪光点闪光点闪光点闪",
      "闪光点闪光点闪光点闪光点闪光点闪光点闪",
    ],
  },
]);
const witNosocomiumList = ref([
  {
    imgUrl: "option_three.png",
    advantage: ["智慧医院", "闪光点闪光点闪光点闪光点闪光点闪光点闪"],
  },
  {
    imgUrl: "option_four.png",
    advantage: [
      "闪光点闪光点闪光点闪光点闪光点闪光点闪",
      "闪光点闪光点闪光点闪光点闪光点闪光点闪",
      "闪光点闪光点闪光点闪光点闪光点闪光点闪",
    ],
  },
]);

function tapChange(index) {
  tapIndex.value = index;
}
</script>
<style scoped lang="scss">
div {
  font-size: 0;
}
.top {
  width: 100%;
  height: 312px;
  position: relative;

  .imgBox {
    display: flex;
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    .tap {
      margin: 0 30px;
      div {
        width: 100%;
        text-align: center;
        color: #060606;
        font-size: 16px;
        margin-top: 20px;
      }
      div:hover {
        color: #c33730;
        cursor: pointer;
      }
    }
  }
}
.list {
  position: relative;
  .advantageBox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    bottom: 60px;
    left: 0;
    text-align: center;
    justify-content: space-evenly;
    p {
      width: 360px;
      font-size: 26px;
      color: #fff;
    }
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 0;
    object-fit: cover;
  }
}
</style>