<template>
  <div>
    <div class="imgBox">
      <div
        v-for="(item, index) in dataObj"
        :key="index"
        class="li"
        @click="sortToggle(item, index)"
        :style="{ color: activeIndex == index ? '#C33730' : '' }"
      >
        <img :src="require(`@/assets/icon/${item.imgUrl}`)" alt="" />
        <br />
        <span>{{ item.sort }}</span>
      </div>
    </div>
    <div
      class="body"
      v-for="(item, index) in sortData.productList"
      :key="index"
    >
      <div class="introduceBox">
        <div class="Big_picture">
          <img :src="require(`@/assets/img/${item.PreviewImg}`)" />
          <div class="punctuationBox">
            <div
              class="punctuation"
              v-for="(AngleImgItem, AngleImgIndex) in item.AngleImg"
              :key="AngleImgIndex"
              @click="PreviewChange(item, AngleImgItem, AngleImgIndex)"
              :class="{
                punctuationTrue: AngleImgIndex == item.PreviewIndex,
                punctuation: AngleImgIndex != item.PreviewIndex,
              }"
            ></div>
          </div>
        </div>
        <div class="introduce">
          <div class="introduceTop">
            <div class="name">{{ item.name }}</div>
            <!-- <div class="Price">{{ item.Price }}</div> -->
          </div>
          <div class="introduceBottom">
            <!-- <div class="specification">
              <div class="introduceTitle">产品规格</div>
              <div
                class="li"
                v-for="(
                  specificationItem, specificationIndex
                ) in item.specification"
                :key="specificationIndex"
              >
                <span>·</span> {{ specificationItem }}
              </div>
            </div> -->
            <div class="Description">
              <div class="introduceTitle">产品描述</div>
              <div class="li">
                <div
                  class="DescriptionBox"
                  v-for="(items, indexs) in item.Description"
                  :key="indexs"
                >
                  <div class="name" v-if="items.name">{{ items.name }}:</div>
                  <div v-else><br /></div>
                  <div>
                    {{ items.content }}
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="case">
              <div class="introduceTitle">产品应用案例</div>
              <div
                class="li"
                style="color: #0077ff"
                v-for="(caseItem, caseIndex) in item.case"
                :key="caseIndex"
              >
                <span>·</span> {{ caseItem }}
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div class="imgUl">
        <img
          v-for="(AngleImgItem, AngleImgIndex) in item.AngleImg"
          :key="AngleImgIndex"
          :src="require(`@/assets/img/${AngleImgItem}`)"
          @click="PreviewChange(item, AngleImgItem, AngleImgIndex)"
        />
      </div>
    </div>
  </div>
</template>

<script setup >
import { ref, reactive, toRefs, onMounted, watch } from "vue";
import { useRoute } from "vue-router";
const route = useRoute();
let activeIndex = ref(0);
const dataObj = ref([
  {
    sort: "智慧导览屏",
    imgUrl: "guide.png",
    productList: [
      {
        name: "智慧导览屏",
        Price: "100,000",
        specification: ["长宽高", "内容物"],
        Description: [
          {
            name: "产品概述",
            content:
              "智慧导览屏是一款集信息展示、智能导航与实时推送于一体的多功能信息终端，专为医院、政府大厅、商业综合体等复杂场景设计。该产品通过先进的AI技术和智能化功能，旨在提升用户体验并为场所管理者提供高效便捷的解决方案。智慧导览屏结合实时数据同步、触控交互、多语言支持等功能，能够灵活应对不同应用场景的需求，同时显著提高信息传递效率和服务质量",
          },
          {
            name: "核心功能",
            content:
              "智慧导览屏的核心功能包括智能导航、互动体验、多语言服务和紧急模式。智能导航功能通过精确的目标定位，帮助用户快速找到目的地，支持多层楼导航、无障碍通道推荐以及电梯与楼梯的指引。互动体验方面，设备配备高灵敏触控屏，用户可以方便地获取所需信息，并支持扫码传递功能，方便将导航路径发送至用户手机，随时随地继续使用。多语言服务功能支持多种语言切换，特别适合国际化场所使用。紧急模式则能在应急情况下自动切换，大屏显示紧急通知和避险指引，确保场所人员在危急时刻得到及时帮助。",
          },
          {
            name: "技术亮点",
            content:
              "智慧导览屏的技术亮点在于模块化定制和数据安全。产品支持功能模块按需配置，能够满足不同客户的个性化需求。数据安全方面，采用高强度加密技术，确保信息安全和用户隐私得到有效保护。同时，设备结合硬件与软件的优化，高清显示屏确保了视觉效果的优质，而友好的用户界面设计大大提升了用户的交互体验，使得操作变得更加简单和直观。",
          },
          {
            name: "适用场景",
            content:
              "智慧导览屏适用于多个场景，特别是那些对信息导航和实时服务有较高需求的场所。在医院，智慧导览屏可以帮助患者精确指引至科室、诊室、检查室等区域，同时发布医院动态、公告和排班信息，提升整体服务效率。政务大厅中，导览屏通过显示窗口办理信息，优化服务流程，提高群众办事效率。商业综合体中，设备能够导航至店铺、餐厅和停车场等区域，提升消费者的购物体验。在交通枢纽，智慧导览屏实时显示航班、列车动态，并提供清晰的站内导航服务，帮助旅客更快捷地找到目的地。",
          },
          {
            name: "产品优势",
            content:
              "智慧导览屏的优势在于其功能的集成化、操作的便捷化、场景的适配性和低维护成本。它能够整合导航、信息推送和服务互动功能，一屏多用，大大提高了效率。用户界面设计简洁易用，操作几乎没有学习成本，任何用户都能轻松上手。该产品具有强大的场景适配性，兼容多种系统与设备，可以根据不同需求灵活调整功能模块。同时，集中化的信息管理系统大幅降低了人工维护的频率，从而有效减少了使用成本。",
          },
          {
            name: "售后保障",
            content:
              "智慧导览屏提供全面的售后保障，确保设备的长期稳定运行。我们设有全天候7*24小时售后响应机制，确保任何问题能够及时解决。此外，定期的设备巡检、功能更新和系统升级服务，将进一步保证设备的持续良好运行。我们还为客户团队提供操作培训，确保用户能够高效利用系统功能，最大化设备的使用价值。",
          },
          {
            name: "",
            content:
              "智慧导览屏通过创新的设计和智能化技术，解决了复杂环境中的信息服务难题，帮助用户实现便捷、高效的智能化服务体验。无论是在医院、政务大厅、商业综合体还是交通枢纽，智慧导览屏都能够提升信息传递效率和服务质量，是现代化场所提升用户体验与管理效率的理想之选。",
          },
        ],
        case: ["产品案例", "产品案例", "产品案例"],
        PreviewImg: "Tour_guide_one.png",
        PreviewIndex: "0",
        AngleImg: [
          "Tour_guide_one.png",
          "Tour_guide_two.png",
          "Tour_guide_three.png",
          "Tour_guide_four.png",
        ],
      },
    ],
  },
  {
    sort: "索引屏",
    imgUrl: "Index_screen.png",
    productList: [
      {
        name: "索引屏",
        Price: "100,000",
        specification: ["长宽高", "内容物"],
        Description: [
          {
            name: "产品概述",
            content:
              "索引屏是一款专为医院、商场、政务大厅等复杂环境设计的智能显示设备，旨在提供清晰、直观的信息展示。它主要用于展示场所内各楼层的房间分布和编号，帮助用户快速定位目标区域的具体位置和功能。通过固定的信息呈现方式，索引屏解决了传统标牌在更新过程中存在的困难和信息陈旧问题，为场所管理者提供了一种高效、低维护成本的解决方案。无论在医院、商场还是公共服务场所，索引屏都能提升信息传递的效率，优化用户体验。",
          },
          {
            name: "产品功能",
            content:
              "索引屏的核心功能是静态信息展示和便捷的信息更新。设备通过高清显示屏，以清晰直观的方式展示各楼层的房间名称、编号及其功能分布，用户可以轻松找到目标区域。信息展示内容包括医院的科室布局、商场的商铺分布等，满足不同场所的需求。为了应对场所中房间名称或编号变更的需求，索引屏的后台系统支持远程更新内容，管理人员可以快速同步更新信息，确保设备所显示的内容始终准确无误。",
          },
          {
            name: "技术特点",
            content:
              "索引屏采用高分辨率的显示屏，确保信息在各种光线条件下都能清晰可见，不会因为环境变化而影响显示效果。此外，索引屏的模块化设计使其可以灵活适应不同场所的楼层数量与房间分布需求，满足各类复杂环境的使用要求。固定内容展示模式降低了设备的互动需求，因此硬件的损耗较低，设备的稳定性和易维护性得到了大幅提升。同时，后台统一管理系统能够集中控制所有索引屏设备，实时进行数据更新和监控，有效降低了人工操作的成本。",
          },
          {
            name: "适用场景",
            content:
              "索引屏适用于多个场景，尤其是在那些信息更新频繁且布局复杂的场所。在医疗机构中，索引屏展示各楼层的科室分布信息，如诊室、检查室和挂号区域位置，帮助患者快速了解医院布局，减少问询时间。在商业综合体中，索引屏可以展示商铺分布及楼层布局信息，提升消费者的购物效率，并明确区域分区与休息设施的位置，优化整体购物体验。在政务大厅中，索引屏清晰地展示各服务窗口的位置及对应楼层，帮助群众快速找到目标服务区。在交通枢纽如车站和机场，索引屏固定显示候车室、售票窗口等位置，简化旅客的导航过程。",
          },
          {
            name: "产品优势",
            content:
              "索引屏的优势主要体现在清晰直观的展示、低维护成本、灵活适应性、实时更新以及专业的外观设计等方面。设备采用简洁明了的内容设计，使得用户可以快速找到所需的信息。固定内容展示方式减少了互动设计需求，从而降低了设备的维护成本。由于支持多楼层、多场景的信息展示，索引屏能够适应各类复杂环境，并且可以灵活配置以满足不同需求。信息更新方面，索引屏能够实现实时同步，避免了传统标牌修改的高成本和低效率问题。外观设计方面，索引屏具有简约大气的外形，与现代化场所的风格高度匹配，进一步提升了场所的整体形象。",
          },
          {
            name: "售后服务",
            content:
              "索引屏提供全面的售后服务保障，确保设备能够长期稳定运行。我们将定期提供设备检查与清洁服务，以确保屏幕显示效果始终保持清晰。7*24小时的远程技术支持也能够及时解决后台操作问题或设备故障，保证设备的高效运行。此外，我们还会为后台管理人员提供内容更新培训，确保他们能够熟练操作设备，保障信息更新的顺畅进行。*24小时售后响应机制，确保任何问题能够及时解决。此外，定期的设备巡检、功能更新和系统升级服务，将进一步保证设备的持续良好运行。我们还为客户团队提供操作培训，确保用户能够高效利用系统功能，最大化设备的使用价值。",
          },
          {
            name: "",
            content:
              "索引屏凭借其稳定可靠的性能和灵活便捷的信息更新方式，成为现代场所实现智慧化管理和优质用户体验的理想选择。无论是在医院、商场、政务大厅还是交通枢纽，索引屏都能够提供高效的信息展示服务，帮助场所优化信息传递和导航效率，同时减少运营和维护成本。",
          },
        ],
        case: ["产品案例", "产品案例", "产品案例"],
        PreviewImg: "Index_screen_one.png",
        AngleImg: [
          "Index_screen_one.png",
          "Index_screen_two.png",
          "Index_screen_three.png",
          "Index_screen_four.png",
        ],
      },
    ],
  },
  {
    sort: "科室小屏",
    imgUrl: "Small_screen.png",
    productList: [
      {
        name: "科室小屏",
        Price: "100,000",
        specification: ["长宽高", "内容物"],
        Description: [
          {
            name: "产品概述",
            content:
              "科室小屏是一款专为单一房间设计的电子门牌设备，广泛应用于医疗机构、办公楼宇、教育场所等需要高效房间状态管理的场景。它不仅可以动态显示房间的基本信息，还能实时更新房间状态，方便用户快速获取即时信息。特别在医疗行业，科室小屏具备支持叫号功能的优势，能够优化患者的就诊流程，减少等待焦虑。通过智能化设计与实时信息交互，科室小屏大幅提升了房间信息传递的效率与准确性，成为现代场所智慧化管理的重要组成部分。",
          },
          {
            name: "产品功能",
            content:
              "科室小屏具备强大的功能设计，首先是基础的房间信息展示，设备可以持续显示房间的名称、编号以及具体用途等基本信息，确保任何时候用户都能快速了解房间的基本状况。此外，科室小屏能够动态展示房间的当前使用状态，例如“使用中”、“空闲”或“清洁中”，并且可以灵活调整状态标识，适应不同场景的需求。设备还具备预约与使用情况显示功能，能够同步展示当前时间段的预约信息和预计结束时间，进一步提高信息的透明度和房间的利用效率。针对医疗行业，科室小屏特别集成了叫号显示功能，可以实时更新患者的排队及就诊进程，减少患者等待的焦虑，同时与医院的挂号与诊疗系统对接，保证数据同步更新。屏幕的亮度与信息内容可根据外部环境自动调节，保障全天候清晰显示，提供了节能与自动调节的智能功能，进一步提升用户体验。",
          },
          {
            name: "技术特点",
            content:
              "科室小屏的技术设计十分先进，采用高分辨率的电子显示屏，确保在不同光线条件下依旧能够保持内容的清晰与明亮。设备内置的智能感应模块能根据人流量自动调节屏幕的亮度或切换状态显示，优化能耗并提升用户体验。无线数据更新功能允许科室小屏通过Wi-Fi或局域网与后台系统连接，实时更新房间状态和预约信息，极大地提升了管理的便利性。科室小屏采用模块化集成设计，能够与现有的智慧管理系统无缝对接，支持多场景扩展应用。设备还注重环保设计，使用低功耗组件和环保材料，即便长时间运行也能有效节能，降低对环境的影响。",
          },
          {
            name: "适用场景",
            content:
              "科室小屏的应用场景非常广泛。在医疗机构中，它可以为各类诊室、检查室、治疗室等房间提供实时信息展示，并且与叫号功能结合，能够显著提升患者的就诊体验，减少等待时间和焦虑情绪。在教育培训机构中，科室小屏可以用于教室、实验室或会议室的显示，展示课程安排、使用状态和预约信息，帮助学生与教师高效使用每一间教室。在办公楼宇中，科室小屏适合用于会议室、办公区域等房间的状态显示，能够实时展示房间的占用情况与预约信息，优化资源利用。在银行、政府办事大厅等公共服务场所，科室小屏可以用于明确服务窗口的当前状态，减少排队等待的混乱，提高整体服务效率。",
          },
          {
            name: "产品优势",
            content:
              "科室小屏在多个方面展现出独特优势。首先，其实时更新功能确保了信息的准确性和及时性，显著提升了用户体验。灵活的功能设置和状态定义使其可以适应不同场景的需求，满足各种环境下的使用要求。科室小屏的安装与维护也非常便捷，设备设计轻巧，易于部署和管理，后台操作简单高效，有效减少了人工管理的工作量。通过智能化的系统集成，科室小屏还可以与现有的管理系统或物联网设备无缝对接，为智慧场所建设提供强大支持。",
          },
          {
            name: "售后服务",
            content:
              "我们为科室小屏提供全面的售后服务支持。首先，设备会定期进行维护与检查，确保其长期稳定运行。后台系统也将持续进行更新与优化，客户可以享受最新的软件功能与扩展支持。此外，我们还提供针对管理员的使用培训和操作指导，帮助客户更好地利用设备，确保其最大化发挥作用。*24小时的远程技术支持也能够及时解决后台操作问题或设备故障，保证设备的高效运行。此外，我们还会为后台管理人员提供内容更新培训，确保他们能够熟练操作设备，保障信息更新的顺畅进行。*24小时售后响应机制，确保任何问题能够及时解决。此外，定期的设备巡检、功能更新和系统升级服务，将进一步保证设备的持续良好运行。我们还为客户团队提供操作培训，确保用户能够高效利用系统功能，最大化设备的使用价值。",
          },
        ],
        case: ["产品案例", "产品案例", "产品案例"],
        PreviewImg: "Small_screen1.png",
        PreviewIndex: "0",
        AngleImg: [
          "Small_screen1.png",
          "Small_screen2.png",
          "Small_screen3.png",
        ],
      },
    ],
  },
  {
    sort: "智能电子吊挂",
    imgUrl: "hang.png",
    productList: [
      {
        name: "智能电子吊挂",
        Price: "100,000",
        specification: ["长宽高", "内容物"],
        Description: [
          {
            name: "产品概述",
            content:
              "智能电子吊挂是一种创新型的吊挂标识系统，专为商场、医院等公共场所设计。不同于传统静态吊挂标识，智能电子吊挂采用电子显示屏，能够动态、灵活地展示各种信息。通过数字化展示，用户可以实时更新标识内容，从而显著优化信息传递的效率。此产品特别适用于需要频繁更新标识信息的场所，极大地替代了传统固定标识的局限性，使得信息更新更加便捷、灵活、环保。智能电子吊挂还支持多屏联动和智能调节亮度等高科技功能，有助于提升场所的管理效率和用户体验。",
          },
          {
            name: "产品功能",
            content:
              "智能电子吊挂具备多个先进功能。首先，它支持动态内容显示，能够展示图文、视频、动画等多种形式的信息，信息内容可以灵活切换，满足不同需求的展示需求。例如，商场可以展示楼层信息、商铺名称、促销活动等内容，医院则可以用于科室和房间的标识。通过后台管理系统，智能电子吊挂可以进行远程管理与更新，管理人员可以通过云平台或局域网随时更新显示内容，省去人工更换标识牌的麻烦。此外，智能电子吊挂支持院区内多屏联动，多个显示屏之间可以同步更新内容，确保信息流转的高效性。设备还具备智能调节亮度与对比度的功能，能够根据环境光线自动调节屏幕的亮度，确保在不同时间段或不同环境下都能清晰显示信息。同时，采用节能型LED显示屏，减少能耗并延长设备使用寿命，进一步降低运营成本。",
          },
          {
            name: "技术特点",
            content:
              "智能电子吊挂采用高清LED或LCD显示屏，保证了显示内容的清晰度和色彩的鲜艳度，适应不同环境的光线条件。设备支持无线传输和远程控制，用户可以通过Wi-Fi或蓝牙连接后台系统，方便进行内容更新和设备管理。为了适应高空安装和长时间使用的需求，智能电子吊挂采用坚固耐用的材料，具备抗震、抗腐蚀功能，确保设备在恶劣环境下稳定运行。设备的模块化结构使得其在安装时更为灵活，能够根据不同空间和场景需求进行调节和扩展。此外，智能电子吊挂可以与其他智能设备或系统对接，如院内的信息管理系统、导航系统等，提升了整体智能化管理水平。",
          },
          {
            name: "适用场景",
            content:
              "智能电子吊挂的应用范围十分广泛，尤其适用于商场、医院、办公楼宇等需要实时更新信息的场所。在商场和购物中心，智能电子吊挂可以展示楼层信息、商铺位置、促销广告等内容，同时快速传递临时信息或紧急通知，如活动安排和闭店时间等。在医院和医疗机构中，智能电子吊挂能够用于科室、诊室、检查室等房间的标识，实时更新患者排队、叫号信息，提供便捷的院区导航和科室引导。对于办公楼宇和企业园区，智能电子吊挂可以显示会议室、办公区等房间的信息，实时更新会议安排和公司新闻，提高员工与访客的工作效率。交通枢纽如车站、机场等公共场所也可以利用智能电子吊挂展示楼层与房间信息，提升旅客的导航效率和体验感。",
          },
          {
            name: "产品优势",
            content:
              "智能电子吊挂具有许多独特优势。首先，内容的实时更新和灵活展示使得用户无需频繁更换标识牌，且信息更新快捷，确保了内容的准确性和时效性。其次，设备支持集中管理和远程控制，简化了管理流程，减少了现场人工操作与维护工作量，降低了运维成本。此外，智能调节亮度和对比度的功能，确保了显示效果的清晰与舒适，尤其适合不同的光照和场景需求。多屏联动功能不仅提高了信息流转效率，还能帮助用户快速获取所需信息，提升了整体场所的运营效率。智能电子吊挂还具备良好的节能效果，自动调节亮度功能可以减少能源消耗，同时采用可回收材料，降低对环境的负担。",
          },
          {
            name: "售后服务",
            content:
              "智能电子吊挂提供全面的售后服务。首先，设备会定期进行检查与维护，确保长期稳定运行。后台系统将持续进行更新与优化，客户可以享受最新的软件功能与扩展支持。我们还提供远程技术支持，解决使用过程中可能出现的问题，确保客户能够高效使用设备。同时，我们为客户提供操作培训和使用指南，帮助用户熟悉设备操作，最大化地发挥其价值。",
          },
          {
            name: "",
            content:
              "智能电子吊挂以其灵活的显示功能、实时更新的内容展示和高效的多屏联动，成为商场、医院等场所信息传递和管理的理想选择。无论是在商场、医院还是交通枢纽，智能电子吊挂都能提供精准、及时的信息展示，为客户提供更高效、更便捷的服务体验，推动场所的智能化管理进程。",
          },
        ],
        case: ["产品案例", "产品案例", "产品案例"],
        PreviewImg: "hang_one.png",
        PreviewIndex: "0",

        AngleImg: ["hang_one.png"],
      },
    ],
  },
  {
    sort: "智能烟蒂收集器",
    imgUrl: "smokestack.png",
    productList: [
      {
        name: "智能烟蒂收集器",
        Price: "100,000",
        specification: ["长宽高", "内容物"],
        Description: [
          {
            name: "产品概述",
            content:
              "智能烟蒂收集器是一款集环保、便捷和智能化管理于一体的公共设施设备，专为高流量区域如城市公共场所、园区、商业广场等设计。该产品采用先进的感应技术与数据收集系统，能够自动识别并收集烟蒂，同时监测设备容量和使用频率，便于高效管理和及时清理。智能烟蒂收集器的引入不仅提升了烟蒂处理的便捷性，还通过数字化管理减少了环境污染，帮助场所实现绿色、低碳的环保目标。",
          },
          {
            name: "产品功能",
            content:
              "智能烟蒂收集器具备多项先进功能，全面提升了烟蒂处理的效率与安全性。首先，内置的智能感应系统能迅速识别烟蒂并将其自动吸入收集仓，确保烟蒂能够集中存储，避免随意丢弃造成污染。设备还具有容量监测功能，当收集仓接近满载时，系统会自动发送提醒，提示管理人员进行清理，避免溢出问题。通过数据统计与分析功能，系统能够记录每日的使用频率与垃圾处理量，并生成详细的统计报告，帮助场所优化垃圾清理计划。此外，智能烟蒂收集器还具备防火设计，采用耐高温材料，防止未完全熄灭的烟蒂引发火灾。设备还支持太阳能供电，具有高效的能源管理功能，降低了长期运行成本。最后，通过温感组件和GPS实时监控功能，能够及时预警设备的高温风险和防盗、防倾倒的安全问题，保障设备安全运行。",
          },
          {
            name: "技术特点",
            content:
              "智能烟蒂收集器拥有多项技术优势，确保了设备的高效、可靠和环保。设备配备的智能感应系统具有快速识别烟蒂的能力，保障了收集过程的便捷性与高效性。模块化结构设计使得设备在需要维护或更换部件时更加方便，降低了维修难度。外壳采用环保材质，符合可持续发展理念，并且所有组件都支持回收，助力环保目标的实现。通过后台管理系统，用户可以远程监控多个设备的运行状态，及时了解设备工作情况，减少人工巡查成本。此外，设备的灵活性还体现在它能够适应不同场景的需求，提供定制化的解决方案。",
          },
          {
            name: "适用场景",
            content:
              "智能烟蒂收集器适用于多个公共场所和商业环境，尤其适合需要管理大量人群的区域。在城市公共区域，智能烟蒂收集器可以安装在广场、步行街、公交站点等地，集中收集烟蒂，保持环境整洁。在商业综合体中，设备可以设置在室外吸烟区，减少商场内部的烟蒂污染问题。在园区与景区，智能烟蒂收集器能够分布在不同位置，有效改善公共区域的环境卫生状况。对于企事业单位，设备提供了一个绿色环保的吸烟区域管理解决方案，有助于提升企业的社会责任形象。",
          },
          {
            name: "产品优势",
            content:
              "智慧导览屏的优势在于其功能的集成化、操作的便捷化、场景的适配性和低维护成本。它能够整合导航、信息推送和服务互动功能，一屏多用，大大提高了效率。用户界面设计简洁易用，操作几乎没有学习成本，任何用户都能轻松上手。该产品具有强大的场景适配性，兼容多种系统与设备，可以根据不同需求灵活调整功能模块。同时，集中化的信息管理系统大幅降低了人工维护的频率，从而有效减少了使用成本。",
          },
          {
            name: "售后服务",
            content:
              "智能烟蒂收集器提供全方位的售后服务保障，确保设备的长期稳定运行。定期巡检与维护服务将帮助客户保持设备的良好工作状态，避免故障影响使用。我们还提供7*24小时的远程技术支持，解决设备操作和系统问题。同时，为确保客户能够最大化利用设备功能，我们为使用团队提供系统操作与维护培训，帮助他们熟悉设备操作和管理流程。",
          },
          {
            name: "",
            content:
              "智能烟蒂收集器凭借其创新技术与环保理念，解决了公共场所烟蒂垃圾处理的难题，不仅提升了处理效率，还优化了环境卫生管理。它是城市及场所实现绿色低碳目标的重要工具，能够大大改善公共区域的卫生状况，同时增强场所的环保形象，是现代公共设施管理的理想选择。",
          },
        ],
        case: ["产品案例", "产品案例", "产品案例"],
        PreviewImg: "smokestack_one.png",
        PreviewIndex: "0",
        AngleImg: [
          "smokestack_one.png",
          "smokestack_two.png",
          "smokestack_three.png",
          "smokestack_four.png",
        ],
      },
    ],
  },
]);
let sortData = ref({});
watch(route, (newRoute) => {
  console.log(newRoute);
  activeIndex.value = newRoute.query.index;
  sortData.value = dataObj.value[activeIndex.value];
});
function sortToggle(item, index) {
  sortData.value = item;
  activeIndex.value = index;
  console.log(item);
}
function PreviewChange(item, AngleImgItem, AngleImgIndex) {
  item.PreviewImg = AngleImgItem;
  item.PreviewIndex = AngleImgIndex;
}
onMounted(() => {
  console.log(route.query.index);
  if (route.query.index) {
    activeIndex.value = route.query.index;
  }
  sortData.value = dataObj.value[activeIndex.value];
});
</script>
<style scoped lang="scss">
.imgBox {
  width: 100%;
  margin-top: 140px;
  display: flex;
  text-align: center;
  justify-content: center;
  box-shadow: 0 4px 6px -1px rgb(185, 185, 185, 0.4);
  .li {
    margin: 30px;
    img {
      width: 100px;
      height: 100px;
      margin-bottom: 20px;
    }
  }
  .li:hover {
    cursor: pointer;
  }
}
.body {
  width: 100%;
  padding: 40px;
  .introduceBox {
    display: flex;
    .Big_picture {
      width: 62.5%;
      height: 900px;
      position: relative;
      .punctuationBox {
        position: absolute;
        width: 100%;
        bottom: 40px;
        left: 0;
        display: flex;
        justify-content: center;
        .punctuation {
          width: 6px;
          height: 6px;
          background-color: #bababa;
          border-radius: 6px;
          margin: 0 7px;
          margin-top: 2px;
        }
        .punctuationTrue {
          margin-top: 0;
          width: 10px;
          height: 10px;
          background-color: #fff;
        }
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
    .introduce {
      flex: 1;
      margin-left: 40px;
      position: relative;
      .introduceTop {
        font-weight: 600;
        .name {
          font-size: 26px;
        }
        .Price {
          font-size: 24px;
          color: #c33730;
          margin-top: 4px;
        }
      }
      .introduceBottom {
        position: absolute;
        bottom: 0;
        left: 0;
        .Description {
          margin: 0;
          .li {
            width: 100%;
            overflow: auto;
            max-height: 800px;
            line-height: 30px;
            ::-webkit-scrollbar {
              display: block;
            }
          }
        }
        .introduceTitle {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 8px;
        }
        .li {
          color: #666666;
          font-size: 14px;
          .DescriptionBox {
            margin-top: 20px;
            .name {
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
  .imgUl {
    margin-top: 10px;
    width: 62.5%;
    img {
      width: 16%;
      height: 150px;
      margin-right: 10px;
    }
  }
}
</style>