module.exports = {
    language: {
        name: 'English'
    },
    user: {
        login:'登录',
        register:'注册',
        loginUsername:'请输入邮箱/手机号',
    }
}
